import "../App.css";
import "./style.css";
import { NavLink, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import QuoteCards from "./QuoteCards";
import { useEffect, useState, useRef } from "react";
import aos from "aos";
import "aos/dist/aos.css";
import CheckOutPlatform from "./CheckOutPlatform";
import HomeInfoPage from "./HomeInfoPage";

export default function Home() {
  const location = useLocation();

  const [width, setWidth] = useState(window?.innerWidth);
  useEffect(() => {
    aos.init({ duration: 2000 });
  }, []);
  useEffect(() => {
    const updateWindowDimensions = () => {
      const newHeight = window.innerWidth;
      setWidth(newHeight);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const containerStyle = {
    backgroundImage: 'url("/images/wr-pricing-header.jpg")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    transform: width > 769 && "rotate(-1.5deg)",
    position: "relative",
    width: width > 769 ? "99.5%" : "100%",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(3 48 73 / 84%)",
  };
  const featureData = [
    {
      title: "Automated Partner/Team Payments feature",
      sDesc: "Automatically send a portion of each payment",
      desc: `Automatically send a portion of each payment directly
    to your Partner, Lender, or Team Member`,
      icon: (
        <Icon
          icon="bytesize:creditcard"
          fontSize={55}
          style={{ color: "#008e00" }}
          className="mb-4"
        />
      ),
    },
    {
      title: "Multiple Loans per Borrower",
      sDesc: `Repeat Buyer? No Sweat. Borrowers manage all their loans
      from one login`,
      desc: `Our platform allows borrowers to manage multiple loans
      effortlessly, providing flexibility and control over
      their financial needs.`,
      icon: (
        <Icon
          icon="mdi:book-multiple"
          fontSize={55}
          style={{ color: "#008e00" }}
          className="mb-4"
        />
      ),
    },
    {
      title: "One-Click Payoff",
      sDesc: `Everyone can generate estimated payoff numbers in just
      one click`,
      desc: ` Say goodbye to complex payment processes. With our
      one-click payoff feature, settling your loans is quick
      and hassle-free.`,
      icon: (
        <Icon
          icon="mdi:account-payment"
          fontSize={55}
          style={{ color: "#008e00" }}
          className="mb-4"
        />
      ),
    },
    {
      title: "Loans Transfer",
      sDesc: `Easily Transfer Loans to another Lender`,
      desc: `Transfer loans smoothly between accounts or borrowers when needed, ensuring flexibility in your financial transactions.`,
      icon: (
        <Icon
          icon="mingcute:transfer-fill"
          fontSize={55}
          style={{ color: "#008e00" }}
          className="mb-4"
        />
      ),
    },
    {
      title: "Multiple Account Creation",
      sDesc: `Team Members, Partners or Lenders can create accounts`,
      desc: `Whether you're a Team Member, Partner, Lender, or Borrower, our platform caters to your specific needs with customizable account types.`,
      icon: (
        <Icon
          icon="mdi:bank"
          fontSize={55}
          style={{ color: "#008e00" }}
          className="mb-4"
        />
      ),
    },
    {
      title: "Multiple Borrowers per Loans",
      sDesc: `Add upto 5 borrowers to a loan`,
      desc: ` Collaborate with co-borrowers seamlessly, making it
      easier than ever to secure loans and share
      responsibilities.`,
      icon: (
        <Icon
          icon="mdi:users"
          fontSize={55}
          style={{ color: "#008e00" }}
          className="mb-4"
        />
      ),
    },
  ];
  const data = [
    {
      title: "30,000",
      desc: "Lines of Custom Code",
      icon: (
        <Icon
          icon="mingcute:code-fill"
          style={{ fontSize: 70, color: "#008e00" }}
        />
      ),
    },
    {
      title: "25,000",
      desc: "Email Reminders Sent",
      icon: (
        <Icon
          icon="mdi:email-fast-outline"
          style={{ fontSize: 70, color: "#008e00" }}
        />
      ),
    },
    {
      title: "50",
      desc: "Feature Updates Since Launch",
      icon: (
        <Icon
          icon="ic:outline-tips-and-updates"
          style={{ fontSize: 70, color: "#008e00" }}
        />
      ),
    },
    {
      title: "5",
      desc: "Minutes to Accept Payments",
      icon: (
        <Icon
          icon="streamline:interface-time-stop-watch-third-quarter-quarters-time-measure-stopwatch-three-seconds-minutes"
          style={{ fontSize: 70, color: "#008e00" }}
        />
      ),
    },
  ];

  const idRef = useRef([]);
  const urlSearchParams = new URLSearchParams(location.search);
  const checkoutParam =
    urlSearchParams.has("walkthrough") || urlSearchParams.has("walkthrough");
  const scrollToView = (view) => {
    console.log(idRef.current[view]?.offsetTop);
    window.scrollTo({
      top: idRef.current[view]?.offsetTop + 270,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (checkoutParam && idRef.current && Object.keys(idRef.current).length > 0)
      scrollToView("walkthrough");
  }, [checkoutParam, idRef.current]);

  return (
    <div style={{ backgroundColor: "#e7edf3" }}>
      <header>
        <div
          className="custom-overlay"
          style={{
            backgroundImage: `url(${"./images/YLL_Landscape-Only.png"})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
        ></div>
        <div className="container h-100">
          <div className="d-flex h-100 text-center align-items-center">
            <div className="w-100 text-white">
              <h1 className="display-3 text-white">
                <img
                  className="custom-img"
                  src="images/white-logo.png"
                  alt="Logo"
                />
              </h1>
              <p className="text-white fs-1 ">Notes Made Easy</p>
              <NavLink
                className="btn btn-success lead mb-0 fs-3 px-5 fw-bold"
                to="Signup"
              >
                Get Started!
              </NavLink>
            </div>
          </div>
        </div>
      </header>
      <div style={{ overflow: "hidden" }}>
        <div
          style={{
            backgroundImage: `url("./images/12141.jpg")`, // Corrected the image path
            backgroundSize: "cover",
            color: "#fff",
            padding: "10vh 5vh",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
            boxShadow: "0 0 0 20000px rgba(255, 255, 255, 0.45) inset", // Corrected the boxShadow property
            marginBottom: "-65px", // Corrected the marginBottom property
          }}
        >
          <div className="container">
            <div className="row gx-5 py-5 pb-2">
              <div className="col-sm-12 col-md-4 col-lg-4 d-flex justify-content-center align-items-center">
                <img
                  alt=""
                  src="/images/6268777.png"
                  className="img-fluid"
                  data-aos="fade-up"
                  style={{ height: 350, width: 400 }}
                />
              </div>
              <div
                className="col-sm-12 col-md-8 col-lg-8"
                data-aos="fade-up-left"
              >
                <h1
                  className="text-success primary-green mb-3 pt-5"
                  style={{ fontSize: "6vh" }}
                >
                  Notes on Easy Mode
                </h1>
                <p className="content pt-3 fs-4 pb-5">
                  <i className="text-muted">
                    If you're looking for more time and fewer distractions from
                    what really matters, then we're here to help. While manually
                    collecting payments, updating statements, sending out
                    follow-up emails, and creating payoffs excite some people...
                    we're pretty sure you don't want to. That's where we come
                    in. We automate away 95% of the nonsense and make managing
                    your note portfolio as easy as possible.
                  </i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div style={containerStyle}>
          <div style={overlayStyle}></div>
          <div
            className="container"
            style={{ transform: width > 769 ? "rotate(1.5deg)" : "none" }}
          >
            <div className="row py-5 mt-5">
              <div
                className="col-md-6 col-lg-6 col-sm-12 d-flex align-items-center firstcol"
                style={{ zIndex: 1 }}
              >
                <div>
                  <h1
                    className="text-left"
                    style={{ fontSize: "6vh", color: "#fff" }}
                  >
                    Effortless Setup
                  </h1>
                  <p
                    className="content text-left pt-3 fs-4"
                    style={{ color: "rgba(255, 255, 255, 0.85)" }}
                  >
                    Get more time for what matters most. We're here to help. Say
                    goodbye to manual payment collection, statement updates,
                    follow-up emails, and payoffs. Our solution removes 95% of
                    the hassle, making note portfolio management a breeze.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12 Secondcol">
                <div className="row">
                  <div className="col-md-6 d-flex align-items-stretch">
                    <div
                      className="card my-2"
                      style={{
                        width: "100%",
                        color: "white",
                        background: "transparent",
                      }}
                    >
                      <div
                        className="card-body"
                        style={{ backgroundColor: "rgba(63, 107, 129, 0.67)" }}
                      >
                        <div className="d-flex justify-content-center">
                          <img
                            className="img-fluid wow shake me-3"
                            data-wow-iteration="4"
                            data-wow-duration="2s"
                            src="images/check.svg"
                            style={{ maxWidth: "70px" }}
                            alt=""
                          />
                        </div>
                        <h3 className="card-title text-center my-3">
                          SIMPLIFY
                        </h3>
                        We want Note management to be one of the easiest parts
                        of your business
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex align-items-stretch">
                    <div
                      className="card my-2"
                      style={{
                        width: "100%",
                        color: "white",
                        background: "transparent",
                      }}
                    >
                      <div
                        className="card-body"
                        style={{ backgroundColor: "rgba(63, 107, 129, 0.67)" }}
                      >
                        <div className="d-flex justify-content-center">
                          <img
                            className="img-fluid wow shake me-3"
                            data-wow-iteration="4"
                            data-wow-duration="2s"
                            src="images/gear.svg"
                            style={{ maxWidth: "70px" }}
                            alt=""
                          />
                        </div>
                        <h3 className="card-title text-center my-3">
                          AUTOMATE
                        </h3>
                        Collect Payments, Generate Payoffs, Add Late Fees and
                        more all without lifting a finger
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 d-flex align-items-stretch">
                    <div
                      className="card my-2"
                      style={{
                        width: "100%",
                        color: "white",
                        background: "transparent",
                      }}
                    >
                      <div
                        className="card-body"
                        style={{ backgroundColor: "rgba(63, 107, 129, 0.67)" }}
                      >
                        <div className="d-flex justify-content-center">
                          <img
                            className="img-fluid wow shake me-3"
                            data-wow-iteration="4"
                            data-wow-duration="2s"
                            src="images/graph.svg"
                            style={{ maxWidth: "70px" }}
                            alt=""
                          />
                        </div>
                        <h3 className="card-title text-center my-3">DATA</h3>
                        Wield a robust set of analytics around your notes
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex align-items-stretch">
                    <div
                      className="card my-2"
                      style={{
                        width: "100%",
                        color: "white",
                        background: "transparent",
                      }}
                    >
                      <div
                        className="card-body"
                        style={{ backgroundColor: "rgba(63, 107, 129, 0.67)" }}
                      >
                        <div className="d-flex justify-content-center">
                          <img
                            className="img-fluid wow shake me-3"
                            data-wow-iteration="4"
                            data-wow-duration="2s"
                            src="images/fast-click.svg"
                            style={{ maxWidth: "70px" }}
                            alt=""
                          />
                        </div>
                        <h3 className="card-title text-center my-3">FAST!</h3>
                        Lenders can be ready to accept ACH Payments in under 10
                        minutes. Borrowers can be ready to pay in under 5!
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundImage: `url("./images/12140.jpg")`, // Corrected the image path
            backgroundSize: "cover",
            color: "#fff",
            textAlign: "center",
            paddingTop: "15vh",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
            boxShadow: "0px 0px 0px 20000px rgba(255, 255, 255, 0.45) inset", // Corrected the boxShadow property
            marginTop: "-20px", // Corrected the marginTop property
          }}
        >
          <div className="container pb-5 custom-overlay">
            <h1
              className="text-success primary-green text-center pb-5 my-3"
              style={{ fontSize: "6vh" }}
            >
              Features
            </h1>
            <div className="row">
              {featureData.map((row) => {
                return (
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="flip-card mt-4">
                      <div className="flip-card-inner shadow border-0 rounded-3">
                        <div className="flip-card-front bg-white p-5 px-sm-4 px-4 px-md-4 rounded-3">
                          {row.icon}
                          <h2 className="fs-3">{row.title}</h2>
                          <p className="text-muted my-4 fs-5 lh-sm">
                            {row.sDesc}
                          </p>
                        </div>
                        <div className="flip-card-back p-5 px-sm-4 px-4 px-md-4 rounded-3 d-flex align-items-center">
                          <div className="text-center fs-4 lh-sm">
                            <p>{row.desc}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* ... (other similar cards) ... */}
            </div>
          </div>
          <div
            style={{
              background:
                "linear-gradient(to bottom, transparent 50%, #008e00 50%)",
            }}
          >
            <div className="container pt-5" style={{ maxWidth: "90%" }}>
              <div className="row m-auto pt-3">
                <div className="col-xxl-12 col-xl-12 mb-4">
                  <div className="card">
                    <div className="card-body d-flex flex-column justify-content-center py-5 py-xl-4">
                      <div className="row align-items-center">
                        {data.map((row) => {
                          return (
                            <div
                              className="col-sm-12 col-md-6 col-lg-3 wow bounceInUp"
                              key={row.title}
                            >
                              <div className="card shadow-none border-0">
                                <div className="card-body text-muted fs-5 text-center">
                                  <h3 className="card-title">{row.icon}</h3>
                                  <h1>{row.title}</h1>
                                  <p>{row.desc}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-4" style={{ backgroundColor: " #008e00" }}>
          <div className="container">
            <div className="my-5">
              <h1
                className=" text-center"
                style={{ fontSize: " 6vh", color: "#fff" }}
              >
                How it Works
              </h1>
            </div>

            <div className="row my-5">
              <div className="col-md-4 col-sm-12 col-lg-4">
                <div
                  className="card border-0 shadow-none"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div className="card-body text-muted fs-5 ">
                    <div
                      className=" bg-white border-0 my-2 d-flex align-items-center justify-content-center"
                      style={{
                        height: "70px",

                        width: "70px",
                        borderRadius: "50%",
                      }}
                    >
                      <h3 className="card-title">
                        <Icon
                          icon="fa6-solid:1"
                          style={{ fontSize: 25, color: "#008e00" }}
                        />
                      </h3>
                    </div>
                    <h3 style={{ color: "#fff" }}>Create an Account</h3>
                    <p style={{ color: "#fff" }}>
                      Begin your journey by signing up for an account. It's
                      quick and easy - just provide some basic information, and
                      you'll be on your way.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 col-lg-4">
                <div
                  className="card border-0 shadow-none"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div className="card-body text-muted fs-5 ">
                    <div
                      className=" bg-white border-0 my-2 d-flex align-items-center justify-content-center"
                      style={{
                        height: "70px",

                        width: "70px",
                        borderRadius: "50%",
                      }}
                    >
                      <h3 className="card-title">
                        <Icon
                          icon="fa6-solid:2"
                          style={{ fontSize: 25, color: "#008e00" }}
                        />
                      </h3>
                    </div>
                    <h3 style={{ color: "#fff" }}>Check Your Email</h3>
                    <p style={{ color: "#fff" }}>
                      After creating an account, keep an eye on your inbox.
                      We'll send you an email with a temporary password. Now you
                      can reset your password for added security.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 col-lg-4">
                <div
                  className="card border-0 shadow-none"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div className="card-body text-muted fs-5 ">
                    <div
                      className=" bg-white border-0 my-2 d-flex align-items-center justify-content-center"
                      style={{
                        height: "70px",

                        width: "70px",
                        borderRadius: "50%",
                      }}
                    >
                      <h3 className="card-title">
                        <Icon
                          icon="fa6-solid:3"
                          style={{ fontSize: 25, color: "#008e00" }}
                        />
                      </h3>
                    </div>
                    <h3 style={{ color: "#fff" }}>Login</h3>
                    <p style={{ color: "#fff" }}>
                      Once you've reset your password, use your registered email
                      address and the current password to log in. From there,
                      you can personalize your account and start exploring all
                      that our platform has to offer.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HomeInfoPage />
        <div
          ref={(element) => {
            idRef.current["walkthrough"] = element;
          }}
        >
          <CheckOutPlatform />
        </div>
        <div
          style={{
            backgroundImage: `url("./images/12141.jpg")`,
            backgroundSize: "cover",
            color: "#fff",
            padding: "5vh",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
            boxShadow: "0px 0px 0px 20000px rgba(255, 255, 255, 0.45) inset", // Corrected the boxShadow property
          }}
        >
          <div className="container py-5">
            <div className="row py-5">
              <div
                className="col-sm-12 col-md-7 col-lg-7"
                data-aos="fade-up-right"
              >
                <h1 className="text-success mb-4" style={{ fontSize: "6vh" }}>
                  OUR JOURNEY
                </h1>
                <p className="content fs-4 pt-3 text-muted fst-italic pb-5">
                  Being a real estate lender can be tough. After searching and
                  searching for a solution for our own portfolios, we just
                  couldn't find a loan servicing platform that was both
                  affordable and high quality. Self-servicing non-owner-occupied
                  real estate loans put us in a unique space. The existing
                  platforms are largely made for huge servicing companies and
                  charge huge fees or didn't meet our quality standards for
                  accuracy and ease of use. Thus, Your Land Loans was born! We
                  want to give you a best-in-class experience you don't have to
                  break the proverbial piggy bank for.
                </p>
              </div>
              <div className="col-sm-12 col-md-5 col-lg-5 d-flex align-items-center">
                <img
                  alt=""
                  src="./images/4273991.jpg" // Corrected the image source
                  className="img-fluid"
                  data-aos="fade-up"
                />
              </div>
            </div>
          </div>
        </div>

        <QuoteCards />
        <div className="card-body d-flex flex-column justify-content-center py-5 py-xl-4">
          <div className="mt-5">
            <h1
              className="text-center text-success primary-green"
              style={{ fontSize: "6vh" }}
            >
              Where you can find us online
            </h1>
          </div>
          <div className="container">
            <div className="row justify-content-center g-3 my-5 g-5">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div
                  className="shadow-sm rounded overflow-hidden bg-white"
                  style={{ height: "45vh" }}
                >
                  <iframe
                    src="https://www.youtube.com/embed/U35kvIClO9I"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    style={{ top: 0, left: 0, width: "100%", height: "100%" }}
                  ></iframe>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div
                  className="shadow-sm rounded overflow-hidden bg-white"
                  style={{ height: "45vh" }}
                >
                  <a href="https://casualfridaysrei.com/episode-421-your-land-loans-with-jt-olmstead/">
                    <span className="et_pb_image_wrap">
                      <img
                        decoding="async"
                        loading="lazy"
                        width="100%"
                        height="100%"
                        src="https://olmstead.properties/wp-content/uploads/2023/07/1.png"
                        alt=""
                        srcSet="https://olmstead.properties/wp-content/uploads/2023/07/1.png 800w, https://olmstead.properties/wp-content/uploads/2023/07/1-480x300.png 480w"
                        sizes="100vw"
                        className="wp-image-7464"
                      />
                    </span>
                  </a>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div
                  className="shadow-sm rounded overflow-hidden bg-white"
                  style={{ height: "45vh" }}
                >
                  <a href="https://www.buzzsprout.com/2092398/12925619-013-jt-olmstead-of-your-land-loans">
                    <span className="et_pb_image_wrap">
                      <img
                        decoding="async"
                        loading="lazy"
                        width="100%"
                        height="100%"
                        src="https://olmstead.properties/wp-content/uploads/2023/07/2.png"
                        alt=""
                        srcSet="https://olmstead.properties/wp-content/uploads/2023/07/2.png 800w, https://olmstead.properties/wp-content/uploads/2023/07/2-480x300.png 480w"
                        sizes="100vw"
                        className="wp-image-7465"
                      />
                    </span>
                  </a>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div
                  className="shadow-sm rounded overflow-hidden bg-white"
                  style={{ height: "45vh" }}
                >
                  <iframe
                    style={{ top: 0, left: 0, width: "100%", height: "100%" }}
                    src="https://www.youtube.com/embed/ez0_IzmeuAs"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
