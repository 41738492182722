import "./App.css";
import Navbar from "./Component/Navbar";
import Home from "./Component/Home";
import Beta from "./Component/Beta";
import Pricing from "./Component/Pricing";
import FAQ from "./Component/FAQ";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./Component/Footer";
import Signup from "./Component/Signup";
import Page404 from "./Component/Page404";
import ConfirmationPage from "./Component/ConfirmationPage";
import Privacy from "./Component/Privacy";
import TermsOfServices from "./Component/TermsOfService";
import ComparisonPage from "./Component/ComparisonPage";
function App() {
  window.history.scrollRestoration = "manual";
  return (
    <div className="page-home nav-fixed sidenav-toggled">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/Beta" element={<Beta />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/Signup:id" element={<Signup />} />
          <Route path="/ConfirmationPage" element={<ConfirmationPage />} />
          <Route path="/404" element={<Page404 />} />
          <Route path="/*" element={<Page404 />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/compare" element={<ComparisonPage />} />
          <Route path="/termsofservice" element={<TermsOfServices />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
